
// @ts-nocheck
export class InitAEM{
  constructor() {
    try {
      this.aes = new (window as any).AES({
        pid: "ZJSrX2",
        user_type: `mozi_youkudsp`,
        //uid: loginInfo?.moziId,
        env: (window as any).pageVars?.app?.env,
        //username: loginInfo?.advertiserName,
        //advertiserId: loginInfo?.advertiserId,
        version: "1.0.1",
        debug: true,
      })
     
      // 初始化SDK
      this.aes.log = this.aes.before(this.aes.log,  (type, data)=> {
        return [type, data]
        
      })
  
      this.pv = this.aes.use((window as any).AESPluginPV, {
        autoPV: true,
        autoLeave: true,
        enableHistory: false,
      });
      window.addEventListener('popstate',  ()=> {
        this.pv.sendPV();
      });
      (window as any).AdsTrackerPlugins=this.aes.use([
        (window as any).AESPluginPV,
        (window as any). AESPluginEvent,
        (window as any).AESPluginJSError,
        (window as any).AESPluginAPI,
        (window as any).AESPluginResourceError,
        (window as any).AESPluginPerf,
        (window as any).AESPluginEventTiming,
        (window as any).AESPluginBlank,
        (window as any).AESPluginEmogine,
        (window as any).AESPluginAutolog,
      ]);
      (window as any).aes = this.aes;
    } catch (e) {
      console.log('aem初始化失败',e)
    }
  }
  sendPV(params){
    this.pv.sendPV(params);
  }
  setConfig(config){
    this.aes.setConfig(config);
  }
}
  